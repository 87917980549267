import Container from '@/components/common/container';
import Layout from '@/components/common/v5/layout';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';

const ThankYouPage = () => {
  const [pdfActive, setPdfActive] = useState(true);

  const query = useStaticQuery(graphql`
    {
      hlimage: file(relativePath: { eq: "hallucination-thankyou.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `);

  return (
    <Layout>
      <Container className="py-16 md:pb-32 md:pt-28">
        <section className="flex flex-col gap-8 md:flex-row md:gap-6">
          <div className="flex flex-col">
            <h1 className="max-w-[776px] text-[40px] font-normal leading-none text-white sm:text-[48px] md:text-[56px] lg:text-[64px] xl:text-[72px]">
              Enjoy Our LLM Hallucination Index Report
            </h1>
            <a
              rel="noreferrer noopener"
              href="https://drive.google.com/uc?export=download&id=1hj2XkTPIGJqmTnOvV8le7YIFwSR2Vo21"
              download={true}
              className="my-6 hidden max-w-xs cursor-pointer bg-hi-100 px-10 py-3.5 text-center font-medium text-white duration-200 hover:bg-hi-60 md:block lg:py-4"
            >
              Download Now
            </a>
          </div>

          <div className="flex grow flex-col items-center justify-center md:items-end md:justify-end">
            <div
              className={`relative aspect-[1/1.41] w-full overflow-hidden rounded-[9px] xl:max-w-lg`}
            >
              <iframe
                src="https://drive.google.com/file/d/1hj2XkTPIGJqmTnOvV8le7YIFwSR2Vo21/preview?usp=sharing"
                className={`${
                  pdfActive ? 'opacity-100' : 'opacity-0'
                } relative z-10 aspect-[1/1.41] w-full rounded-[9px] bg-hi-2 duration-200`}
                allow="autoplay"
              ></iframe>
              {!pdfActive && (
                <div className="absolute inset-0 z-10 bg-white">
                  <button
                    className={`relative z-10 block aspect-[1/1.41] w-full rounded-[9px] duration-200 hover:opacity-80`}
                    onClick={() => setPdfActive(true)}
                  >
                    <GatsbyImage
                      image={query.hlimage.childImageSharp.gatsbyImageData}
                      alt="Hallucination Index"
                      className="w-full rounded-[9px]"
                    />
                  </button>
                </div>
              )}
            </div>
            <a
              rel="noreferrer noopener"
              href="https://drive.google.com/uc?export=download&id=1hj2XkTPIGJqmTnOvV8le7YIFwSR2Vo21"
              download={true}
              className="my-6 block w-full cursor-pointer bg-hi-100 px-10 py-3.5 text-center font-medium text-white duration-200 hover:bg-hi-60 md:hidden lg:py-4"
            >
              Download Now
            </a>
          </div>
        </section>
      </Container>
    </Layout>
  );
};

export default ThankYouPage;
